
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import * as moment from 'moment-mini-ts';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';

export class FileUploaderOptions {
    handleUploadOutside: boolean;
    uploadBtnText: string;
}

@Injectable({ providedIn: 'root' })
export class FileService {
    private _baseURL = '/api/files/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private httpClient: HttpClient) { }

    postFile(fileToUpload: File): Observable<boolean> {
        const formData: FormData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);

        return this.httpClient.post<boolean>(this._baseURL + 'upload', formData, this.headerOptions);
    }


    public removeSpecialCharsFromFilename(filename) {
        // get extension so we can add it after stripping non-alpha-numeric chars
        const fileExt = filename.split('.').pop();
        // return new filename. Add extension back to it
        const dateStampDate = new Date();
        const dateStamp = (dateStampDate.getMonth() + 1).toString() + dateStampDate.getDate().toString() + dateStampDate.getFullYear().toString() +
            dateStampDate.getMinutes().toString() + dateStampDate.getSeconds().toString();

        return filename.replace(/\W/g, '') + dateStamp + '_.' + fileExt;
    }


    readJsonFile(_filename): Observable<any> {
        return this.httpClient.get(_filename, { responseType: 'text' }).pipe(
            map(res => {
                try {
                    console.log('Results of file: ', res);
                    return JSON.parse(res[0]);
                } catch (_err) {
                    console.log('Error parsing json: ', _err);
                    return null;
                }
            })
        )

        // return this.httpClient.get(_filename, {responseType: 'text'});
    }


    public readJsonFile2(_url: string): Observable<any> {

        // Call the http GET
        return this.httpClient.get(_url, this.headerOptions).pipe(
            map(this.extractData),
            catchError(this.handleError)
        );
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error.message);
        } else {
            // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` + `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }


    private extractData(res: Response) {
        console.log('Results: ', res);

        let body = res;
        return body || {};
    }


    public getJSON(_file): Observable<any> {
        return this.httpClient.get(_file);
    }


    uploadSingle(file: any): Observable<HttpEvent<any>> {
        const formDataObject: FormData = null;
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.httpClient.post<any>(this._baseURL + 'upload', formData, { reportProgress: true, observe: 'events' });
    }


    public upload(files: Set<File>): Observable<HttpEvent<any>[]> {
        // this will be the our resulting map

        const formDataObjects: FormData[] = [];

        files.forEach(file => {
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            formDataObjects.push(formData);
        });


        const httpRequests = formDataObjects.map((formData) =>
            this.httpClient.post<any>(this._baseURL + 'upload', formData, { reportProgress: true, observe: 'events' })
        );

        return forkJoin(httpRequests);
    }


    public uploadToSpecifedFolder(files: Set<File>, _filePath): Observable<HttpEvent<any>[]> {
        const formDataObjects: FormData[] = [];

        files.forEach(file => {
            if (file && file.name) {
                const formData: FormData = new FormData();
                formData.append('file', file, file.name);
                formDataObjects.push(formData);
            }
        });

        const httpRequests = formDataObjects.map((formData) =>
            this.httpClient.post<any>(this._baseURL + 'upload/' + _filePath, formData, { reportProgress: true, observe: 'events' })
        );

        return forkJoin(httpRequests);
    }


    public typeOfFile(filename): string {
        let fileType = 'link';
        const fileExt = filename.split('.').pop();
        // console.log('Ext type: ', fileExt);

        if (filename === '') return '';

        if (fileExt === 'mp4' || fileExt === 'webm' || fileExt === 'ogg') fileType = 'video';
        else if (fileExt === 'jpeg' || fileExt === 'jpg' || fileExt === 'gif' || fileExt === 'png' || fileExt === 'bmp') fileType = 'image';
        else if (fileExt === 'pdf') fileType = 'pdf';

        return fileType;
    }


    async valueForKey(_fieldsToShow, _moduleFields, key, value, recordDetails, _rowInfo) {
        return new Promise(async (resolve, reject) => {
            // console.log('_moduleFields: ',_moduleFields);
            // console.log('Looking for key in module fields of: ', key);

            const fieldDef = _moduleFields.find(_mf => _mf.fieldName === key);

            // console.log('Field def: ', fieldDef);
            // console.log('value: ', value);

            if ((fieldDef && fieldDef !== undefined)) {
                // console.log('fieldDef: ', fieldDef);

                if (fieldDef['type']['inputType'] === 'Date') {
                    recordDetails[key] = (value) ? moment(value).format("MM/DD/YY") : '';
                } else if (fieldDef['type']['inputType'] === 'DateTime') {
                    recordDetails[key] = (value) ? moment(value).format("MM/DD/YY, h:mm:ss a") : '';
                } else if (fieldDef['type']['inputType'] === 'Relate') {
                    // console.log('fieldDef: ', fieldDef);
                    // console.log('Relate Field: ', key);
                    // console.log('Relate Value: ', value);

                    let nameForValue = '';

                    if (value && value['name']) {
                        nameForValue = value['name'];
                    } else if (value && value['first_name']) {
                        nameForValue = value['first_name'] + ' ' + value['last_name'];
                    }

                    recordDetails[key] = nameForValue;
                } else if (fieldDef['type']['inputType'] === 'MultiRelate') {
                    // console.log('Multi Relate Field: ', key);
                    // console.log('Multi Relate Value: ', value);

                    const multiArray = Object.assign([], value);

                    if (multiArray && multiArray.length > 0) {
                        let idString = '';

                        multiArray.forEach(_item => {
                            const nameForValue = (_item && _item !== undefined) ? (_item['name'] && _item['name'] !== undefined) ? _item['name'] : _item : null;
                            if (nameForValue && nameForValue !== undefined) {
                                if (idString.length) idString += ', ';
                                idString += nameForValue;
                            }
                        });
                        recordDetails[key] = idString;
                    }
                } else {
                    recordDetails[key] = value;
                }
            } else {
                let relateVal = value;

                // console.log('Value has a ....');

                if (key.includes('date')) {
                    relateVal = (relateVal) ? moment(relateVal).format("MM/DD/YY") : '';
                } else if (value && value._id) {
                    // related record.
                    if (value.name) relateVal = value.name;
                    else if (value.first_name) relateVal = value.first_name + ' ' + value.last_name;
                }

                recordDetails[key] = relateVal;
            }

            // console.log('****** Processed value for this key. Value: ', recordDetails[key]);
            // console.log('Record Details: ', recordDetails);

            resolve(recordDetails[key]);
        });
    }


    async processGroup(_fieldsToShow, _moduleFields, _tableData, groupedkey) {
        return new Promise(async (resolve, reject) => {
            const groupData = [];

            const fixedTermsPromise = _tableData[groupedkey].map(async (_rowInfo) => {
                let recordDetails = {};

                // get value for each shit.
                for (const [key, value] of Object.entries(_rowInfo)) {
                    if (_fieldsToShow.includes(key)) {
                        // console.log('key: ', key);
                        // console.log('value: ', value);
                        // console.log('This is a wanted field. Processing and adding.');
                        recordDetails[key] = await this.valueForKey(_fieldsToShow, _moduleFields, key, value, recordDetails, _rowInfo);
                    }
                }

                // console.log('ALL FIELDS PROCESSED FOR THIS GROUP ROW');
                // console.log('Record Details: ', recordDetails);

                groupData.push(recordDetails);
            });

            Promise.all(fixedTermsPromise).then(() => {
                resolve(groupData);
            })
        });
    }


    public buildExportForReportSpreadsheet(_fieldsToShow, _moduleFields, _tableData, _groups = null) {
        // Use passed fields and table data to create a spreadsheet
        return new Promise(async (resolve) => {
            // console.log('_fieldsToShow: ', _fieldsToShow);
            // console.log('_moduleFields: ', _moduleFields);
            // console.log('_tableData: ', _tableData);
            // console.log('_groups: ', _groups);
            // console.log('_tableData COPY CHECKPOINT: ', {..._tableData});

            let massagedData = null;

            if (_groups && _groups.length > 0) {
                const fixedTermsPromise = Object.entries(_tableData).map(async ([groupedkey, groupedvalue]) => {
                    _tableData[groupedkey] = await this.processGroup(_fieldsToShow, _moduleFields, _tableData, groupedkey);
                });

                Promise.all(fixedTermsPromise).then(() => {
                    massagedData = _tableData;

                    // console.log('_fieldsToShow: ', _fieldsToShow);
                    // console.log('massagedData: ', massagedData);

                    let _columns = _fieldsToShow;

                    if (_fieldsToShow.length && _fieldsToShow[0].fieldName) {
                        _columns = _fieldsToShow.map(_field => { return { field: _field.fieldName, label: _field.label } });
                    }

                    // console.log('Columns: ', _columns);

                    const exportData = {
                        groups: _groups,
                        columns: _columns,
                        moduleFields: _moduleFields.map(_mf => {
                            return { field: _mf.fieldName, fieldName: _mf.fieldName, label: _mf.label }
                        }),
                        data: massagedData
                    };

                    // console.log('Export Data: ', exportData);

                    resolve(exportData);
                });
            } else {
                massagedData = [];

                const fixedTermsPromise = _tableData.map(async (_rowInfo) => {
                    let recordDetails = {};

                    // get value for each shit.
                    for (const [key, value] of Object.entries(_rowInfo)) {
                        // console.log('key: ', key);
                        // console.log('value: ', value);

                        recordDetails[key] = await this.valueForKey(_fieldsToShow, _moduleFields, key, value, recordDetails, _rowInfo);
                    }

                    // console.log('ALL FIELDS PROCESSED');
                    // console.log('Record Details: ', recordDetails);

                    massagedData.push(recordDetails);
                });

                Promise.all(fixedTermsPromise).then(() => {
                    // console.log('_fieldsToShow: ', _fieldsToShow);
                    // console.log('massagedData: ', massagedData);

                    let _columns = _fieldsToShow;

                    if (_fieldsToShow.length && _fieldsToShow[0].fieldName) {
                        _columns = _fieldsToShow.map(_field => { return { field: _field.fieldName, label: _field.label } });
                    }


                    // COLUMNS DOES NOT HAVE accounts_placements.....
                    // console.log('Columns: ', _columns);

                    const exportData = {
                        groups: _groups,
                        columns: _columns,
                        moduleFields: _moduleFields.map(_mf => {
                            return { field: _mf.fieldName, fieldName: _mf.fieldName, label: _mf.label }
                        }),
                        data: massagedData
                    };

                    // console.log('Export Data: ', exportData);

                    resolve(exportData);
                });
            }
        });
    }


    public buildExportForSpreadsheet(_moduleFields, _tableData, _groups = null, _moduleName: string = null) {
        // Use passed fields and table data to create a spreadsheet
        return new Promise(async (resolve) => {
            // console.log('_moduleFields: ', _moduleFields);
            // console.log('_tableData: ', _tableData);

            let massagedData = null;
            const isTaskRecords: boolean = (_moduleName === 'tasks') ? true : false; // If the module is tasks we need to modify certain field values.
            const specialTaskFields: string[] = ['populated-related_record', 'record_name', 'related_record']; // For tasks, these fields need subfields extracted and are special cases. So build them outside loop and just assign the values.
            const hasModuleEmailField: boolean = (_moduleFields?.length && _moduleFields.findIndex(_mf => _mf?.fieldName === 'email') > -1); // See if the module has the email field on it.
            // console.log('Has module email field: ', hasModuleEmailField);

            if (_groups && _groups.length > 0) {
                // massagedData = _tableData;
                // console.log('Has Groups: ', _groups);

                Object.entries(_tableData).forEach(([groupedkey, groupedvalue]) => {
                    // console.log('_tableData[groupedkey]: ', _tableData[groupedkey]);

                    _tableData[groupedkey] = _tableData[groupedkey].map(_rowInfo => {
                        let recordDetails = {};

                        // If the module has an email field we need the email field present to build email string if not set and one of the virtual email fields is present.
                        if ((hasModuleEmailField && _rowInfo['email'] == undefined) && (_rowInfo['email_addresses']?.length || _rowInfo['record_emails']?.length)) _rowInfo['email'] = null;

                        // If these are task records, build the values for the any special field.
                        if (isTaskRecords) {
                            // _rowInfo['related_record'] = this.getModuleNameFromRelatedRecord(_rowInfo);
                            const relatedRecordName = this.getNameFromPopulatedRelatedRecord(_rowInfo); // Just assign the record name found here for all values below.

                            // Prod may not have some of these fields displayed or created. However, they may be added and were in local so add them just incase.
                            _rowInfo['related_record'] = relatedRecordName;
                            _rowInfo['populated-related_record'] = relatedRecordName;
                            _rowInfo['record_name'] = relatedRecordName;
                        }

                        for (const [key, value] of Object.entries(_rowInfo)) {
                            // console.log('key: ', key);
                            // console.log('value: ', value);

                            const fieldDef = _moduleFields.find(_mf => _mf.fieldName === key);

                            // If this is a task and the key is one of these special fields, use the values already built above to prevent being overwrote. Otherwise, try to use the fieldDef to build the values.
                            if (isTaskRecords && specialTaskFields.includes(key)) {
                                recordDetails[key] = (value != undefined) ? value : '';
                                // console.log(`Setting ${key} to: `, recordDetails[key]);
                            } else if ((fieldDef && fieldDef !== undefined)) {
                                // console.log('fieldDef: ', fieldDef);

                                if (fieldDef['type']['inputType'] === 'Date') {
                                    recordDetails[key] = (value) ? moment(value).format("MM/DD/YY") : '';
                                } else if (fieldDef['type']['inputType'] === 'DateTime') {
                                    recordDetails[key] = (value) ? moment(value).format("MM/DD/YY, h:mm:ss a") : '';
                                } else if (fieldDef['type']['inputType'] === 'EmailAddress') {
                                    // console.log('This is a email address field...')
                                    recordDetails[key] = this.getEmailStringFromRecordDetails(_rowInfo);
                                } else if (fieldDef['type']['inputType'] === 'Relate') {
                                    // console.log('fieldDef: ', fieldDef);
                                    // console.log('Relate Field: ', key);
                                    // console.log('Relate Value: ', value);

                                    let nameForValue = '';

                                    if (value && value['name']) {
                                        nameForValue = value['name'];
                                    } else if (value && value['first_name']) {
                                        nameForValue = value['first_name'] + ' ' + value['last_name'];
                                    }

                                    recordDetails[key] = nameForValue;
                                } else if (fieldDef['type']['inputType'] === 'MultiRelate') {
                                    // console.log('Multi Relate Field: ', key);
                                    // console.log('Multi Relate Value: ', value);

                                    const multiArray = Object.assign([], value);

                                    if (multiArray && multiArray.length > 0) {
                                        let idString = '';

                                        multiArray.forEach(_item => {
                                            const nameForValue = (_item && _item !== undefined) ? (_item['name'] && _item['name'] !== undefined) ? _item['name'] : _item : null;
                                            if (nameForValue && nameForValue !== undefined) {
                                                if (idString.length) idString += ', ';
                                                idString += nameForValue;
                                            }
                                        });
                                        recordDetails[key] = idString;
                                    }
                                } else {
                                    recordDetails[key] = value;
                                }
                            }
                        }

                        return recordDetails;
                    });
                });

                massagedData = _tableData;
            } else {
                massagedData = _tableData.map(_rowInfo => {
                    // console.log('Row Info: ', _rowInfo);
                    let recordDetails = {};

                    // If the module has an email field we need the email field present to build email string if not set and one of the virtual email fields is present.
                    if ((hasModuleEmailField && _rowInfo['email'] == undefined) && (_rowInfo['email_addresses']?.length || _rowInfo['record_emails']?.length)) _rowInfo['email'] = null;

                    // If these are task records, build the values for the any special field.
                    if (isTaskRecords) {
                        // _rowInfo['related_record'] = this.getModuleNameFromRelatedRecord(_rowInfo);
                        const relatedRecordName = this.getNameFromPopulatedRelatedRecord(_rowInfo); // Just assign the record name found here for all values below.

                        // Prod may not have some of these fields displayed or created. However, they may be added and were in local so add them just incase.
                        _rowInfo['related_record'] = relatedRecordName;
                        _rowInfo['populated-related_record'] = relatedRecordName;
                        _rowInfo['record_name'] = relatedRecordName;
                    }

                    for (const [key, value] of Object.entries(_rowInfo)) {
                        // console.log('************ Key: ', key);
                        const fieldDef = _moduleFields.find(_mf => _mf.fieldName === key);
                        // console.log('Field Def: ', fieldDef)

                        // If this is a task and the key is one of these special fields, use the values already built above to prevent being overwrote. Otherwise, try to use the fieldDef to build the values.
                        if (isTaskRecords && specialTaskFields.includes(key)) {
                            recordDetails[key] = (value != undefined) ? value : '';
                            // console.log(`Setting ${key} to: `, recordDetails[key]);
                        } else if ((fieldDef && fieldDef !== undefined)) {
                            // console.log('fieldDef: ', fieldDef);

                            if (fieldDef['type']['inputType'] === 'Date') {
                                recordDetails[key] = (value) ? moment(value).format("MM/DD/YY") : '';
                            } else if (fieldDef['type']['inputType'] === 'DateTime') {
                                recordDetails[key] = (value) ? moment(value).format("MM/DD/YY, h:mm:ss a") : '';
                            } else if (fieldDef['type']['inputType'] === 'EmailAddress') {
                                // console.log('This is a email address field...')
                                recordDetails[key] = this.getEmailStringFromRecordDetails(_rowInfo);
                            } else if (fieldDef['type']['inputType'] === 'Relate') {
                                // console.log('fieldDef: ', fieldDef);
                                // console.log('Relate Field: ', key);
                                // console.log('Relate Value: ', value);

                                let nameForValue = '';

                                if (value && value['name']) {
                                    nameForValue = value['name'];
                                } else if (value && value['first_name']) {
                                    nameForValue = value['first_name'] + ' ' + value['last_name'];
                                }

                                recordDetails[key] = nameForValue;
                            } else if (fieldDef['type']['inputType'] === 'MultiRelate') {
                                // console.log('Multi Relate Field: ', key);
                                // console.log('Multi Relate Value: ', value);

                                const multiArray = Object.assign([], value);

                                if (multiArray && multiArray.length > 0) {
                                    let idString = '';

                                    multiArray.forEach(_item => {
                                        const nameForValue = (_item && _item !== undefined) ? (_item['name'] && _item['name'] !== undefined) ? _item['name'] : _item : null;
                                        if (nameForValue && nameForValue !== undefined) {
                                            if (idString.length) idString += ', ';
                                            idString += nameForValue;
                                        }
                                    });
                                    recordDetails[key] = idString;
                                }
                            } else {
                                recordDetails[key] = value;
                            }
                        }
                    }

                    return recordDetails;
                });
            }

            const exportData = {
                groups: _groups,
                columns: _moduleFields.map(_field => { return { field: _field.fieldName, label: _field.label } }),
                data: massagedData
            };
            resolve(exportData);
        });
    }


    getEmailStringFromRecordDetails(_record: any = null): string {
        let _emailStringToReturn = '';
        let email_addresses: any[] = _record['email_addresses'];

        if (!email_addresses?.length && _record['record_emails']?.length) email_addresses = _record['record_emails']; // Use local emails if no email addresses found.

        // console.log('Email Addresses', email_addresses);

        if (email_addresses?.length > 0) {
            _emailStringToReturn = email_addresses
                .filter(_e => _e) // filter out any falsy values.
                .map(_ea => {
                    if (_ea?.email_address != undefined) return _ea.email_address;
                    else if (_ea?._id != undefined) return _ea._id; // Return _id as a fallback if email_address isn't present.
                    else return _ea; // If neither exists, return the value as is.
                })
                .join(', '); // Join emails into a comma-separated string.
        } else if (_record['email'] != undefined && _record['email'].length) {
            _emailStringToReturn = _record['email'];
        }

        // console.log('Email Address String: ', _emailStringToReturn);
        return _emailStringToReturn;
    }


    getModuleNameFromRelatedRecord(_record: any = null): string {
        const relatedRecord = _record['related_record'] || null;
        const relatedRecordModuleNameToReturn = (relatedRecord != undefined && relatedRecord['moduleName'] !== undefined) ? relatedRecord['moduleName'] : '';

        // console.log('Related Record Module Name: ', relatedRecordModuleNameToReturn);
        return relatedRecordModuleNameToReturn;
    }


    getNameFromPopulatedRelatedRecord(_record: any = null): string {
        let populatedRelatedRecordNameToReturn = '';
        const populatedRelatedRecord = _record['populated-related_record'] || null;

        if (populatedRelatedRecord != undefined && populatedRelatedRecord['name'] != undefined) {
            populatedRelatedRecordNameToReturn = populatedRelatedRecord['name'];
        } else if (populatedRelatedRecord != undefined && (populatedRelatedRecord['first_name'] != undefined || populatedRelatedRecord['last_name'] != undefined)) {
            let nameToUse = (populatedRelatedRecord['first_name'] != undefined) ? populatedRelatedRecord['first_name'] + ' ' : '';
            if (populatedRelatedRecord['last_name'] != undefined) nameToUse += populatedRelatedRecord['last_name'];

            populatedRelatedRecordNameToReturn = nameToUse;
        }

        // console.log('Populated Related Record Name: ', populatedRelatedRecordNameToReturn);
        return populatedRelatedRecordNameToReturn;
    }


    uploadVoiceshot(_file, _userId) {
        const fd = new FormData();
        // fd.append(`voiceshots/${_userId}/voiceshots/`, _file, _file?.name);
        fd.append(`voiceshots/`, _file, _file?.name);

        return this.httpClient.post(this._baseURL + 'upload-file-to-path', fd);
    }


    exportToSpreadsheet(dataToExport): Observable<any> {
        return this.httpClient.post(this._baseURL + 'spreadsheet/export-to-spreadsheet', dataToExport, this.headerOptions);
    }
}